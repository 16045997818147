import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const Header = styled.header`
  background: ${props => props.theme.colors.primary};
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    text-align: right;
    width: 60%;
    float: left;
  }

  li {
    display: inline-block;
    margin-left: 1em;
  }

  img {
    max-width: 200px;
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.text};
    &:hover {
      color: white;
    }
  }
`
const Logo = styled.a`
  width: 100%;
  float: center;
  text-align: center;
`

const activeLinkStyle = {
  color: "white"
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Header>
      <Nav>
        <center>
          <a href="/">
            <img
              src="/images/uusi-kasino.webp"
              alt="Uusi Kasino"
              width="280"
              height="101"
            />
          </a>
        </center>
        <ul>
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
